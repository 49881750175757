import { useEffect, useState } from "react";
import Link from "./Link";
import { useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import ArrowRight from "./icons/ArrowRight";
import IconMenu from "./icons/Menu";

const Menu = () => {
  const { pathname } = useLocation();
  const { locale } = useIntl();

  const thisURL = pathname.substring(3);

  useEffect(() => {
    setVisibleKnowledge(false);
    setVisibleLanguage(false);
    setVisibleBurger(false);
  }, [pathname]);

  var localeInURL = locale === undefined ? "" : "/" + locale;

  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleKnowledge, setVisibleKnowledge] = useState(true);
  const [visibleLanguage, setVisibleLanguage] = useState(true);
  const [visibleBurger, setVisibleBurger] = useState(true);
  const toggleMenu = () => setVisibleMenu((x) => !x);

  return (
    <div>
      <div className="gradient-blur"></div>
      <div className="menuwrapper">
        <div className="menu">
          <div className="columns">
            <div className="column">
              <Link href={localeInURL + "/"} className="logo"></Link>
            </div>
            <div className="column mobile">
              <div id="menuIcon" className="a" onClick={() => toggleMenu()}>
                <div className="iconMenu">
                  <IconMenu></IconMenu>
                </div>
              </div>
            </div>
            <div
              className={
                visibleMenu ? "column mainmenu" : "column mainmenu hide"
              }
            >
              <div className="columns">
                <Link
                  href={localeInURL + "/case"}
                  className={
                    pathname.indexOf("/case") > -1
                      ? "selected column"
                      : "column"
                  }
                  onClick={() => toggleMenu()}
                >
                  <FormattedMessage
                    id="button_case"
                    defaultMessage="Case studies"
                    values={{ what: "react-intl" }}
                  />
                  <div className="iconArrowRight">
                    <ArrowRight></ArrowRight>
                  </div>
                </Link>

                <Link
                  href={localeInURL + "/method"}
                  className={
                    pathname.indexOf("/method") > -1
                      ? "selected column"
                      : "column"
                  }
                  onClick={() => toggleMenu()}
                >
                  <FormattedMessage
                    id="button_method_short"
                    defaultMessage="Method"
                    values={{ what: "react-intl" }}
                  />
                  <div className="iconArrowRight">
                    <ArrowRight></ArrowRight>
                  </div>
                </Link>

                <div className="a column">
                  <input
                    type="checkbox"
                    id="knowledgecheckbox"
                    checked={visibleKnowledge ? true : false}
                    onClick={() => setVisibleKnowledge((x) => !x)}
                  ></input>
                  <label htmlFor="knowledgecheckbox">
                    <FormattedMessage
                      id="button_insight"
                      defaultMessage="Insight"
                      values={{ what: "react-intl" }}
                    />
                  </label>
                  <div className="knowledge_submenu">
                    <Link
                      className={
                        pathname.endsWith(
                          "/insights/get_started_with_influencer_marketing"
                        )
                          ? "selected"
                          : ""
                      }
                      href={
                        localeInURL +
                        "/insights/get_started_with_influencer_marketing"
                      }
                      onClick={() => toggleMenu()}
                    >
                      <FormattedMessage
                        id="button_knowledgesubmenu1"
                        defaultMessage="Get started with Influencer Marketing"
                        values={{ what: "react-intl" }}
                      />
                    </Link>
                    <Link
                      className={
                        pathname.endsWith(
                          "/insights/how_to_become_a_professional_influencer"
                        )
                          ? "selected"
                          : ""
                      }
                      href={
                        localeInURL +
                        "/insights/how_to_become_a_professional_influencer"
                      }
                      onClick={() => toggleMenu()}
                    >
                      <FormattedMessage
                        id="button_knowledgesubmenu2"
                        defaultMessage="How to Become a Professional Influencer"
                        values={{ what: "react-intl" }}
                      />
                    </Link>
                    <Link
                      className={
                        pathname.endsWith(
                          "/insights/how_to_succeed_with_influencer_marketing"
                        )
                          ? "selected"
                          : ""
                      }
                      href={
                        localeInURL +
                        "/insights/how_to_succeed_with_influencer_marketing"
                      }
                      onClick={() => toggleMenu()}
                    >
                      <FormattedMessage
                        id="button_knowledgesubmenu3"
                        defaultMessage="How to Succeed with Influencer Marketing"
                        values={{ what: "react-intl" }}
                      />
                    </Link>
                    <Link
                      className={
                        pathname.indexOf("/webinars") > -1 ? "selected" : ""
                      }
                      href={localeInURL + "/webinars"}
                      onClick={() => toggleMenu()}
                    >
                      <FormattedMessage
                        id="button_knowledgesubmenu5"
                        defaultMessage="Webinars"
                        values={{ what: "react-intl" }}
                      />
                    </Link>
                    <Link
                      className={
                        pathname.indexOf("/newsletter") > -1 ? "selected" : ""
                      }
                      href={localeInURL + "/newsletter"}
                      onClick={() => toggleMenu()}
                    >
                      <FormattedMessage
                        id="button_knowledgesubmenu4"
                        defaultMessage="Newsletter"
                        values={{ what: "react-intl" }}
                      />
                    </Link>
                  </div>
                </div>

                <Link
                  href={localeInURL + "/contact"}
                  className={
                    pathname.indexOf("/contact") > -1
                      ? "selected column"
                      : "column"
                  }
                  onClick={() => toggleMenu()}
                >
                  <FormattedMessage
                    id="button_contact"
                    defaultMessage="Meet the staff"
                    values={{ what: "react-intl" }}
                  />
                  <div className="iconArrowRight">
                    <ArrowRight></ArrowRight>
                  </div>
                </Link>

                <Link
                  href={localeInURL + "/career"}
                  className={
                    pathname.indexOf("/career") > -1
                      ? "selected column"
                      : "column"
                  }
                  onClick={() => toggleMenu()}
                >
                  <FormattedMessage
                    id="button_work"
                    defaultMessage="Career"
                    values={{ what: "react-intl" }}
                  />
                  <div className="iconArrowRight">
                    <ArrowRight></ArrowRight>
                  </div>
                </Link>

                <div className="a column last">
                  <input
                    type="checkbox"
                    id="burgercheckbox"
                    checked={visibleBurger ? true : false}
                    onClick={() => setVisibleBurger((x) => !x)}
                  ></input>
                  <label htmlFor="burgercheckbox">
                    <IconMenu></IconMenu>
                  </label>

                  <div className="submenuburger">
                    <Link
                      href={localeInURL + "/GoldStandard"}
                      className={
                        pathname.indexOf("/GoldStandard") > -1
                          ? "selected "
                          : ""
                      }
                      onClick={() => toggleMenu()}
                    >
                      <FormattedMessage
                        id="button_goldstandard2"
                        defaultMessage="Gold Standard"
                        values={{ what: "react-intl" }}
                      />
                      <div className="iconArrowRight">
                        <ArrowRight></ArrowRight>
                      </div>
                    </Link>
                    <div className="a">
                      <input
                        type="checkbox"
                        id="languagecheckbox"
                        checked={visibleLanguage ? true : false}
                        onClick={() => setVisibleLanguage((x) => !x)}
                      ></input>
                      <label htmlFor="languagecheckbox">
                        {"Language "}
                        <span className="langvalue">
                          {locale === "sv"
                            ? "Svenska"
                            : locale === "da"
                            ? "Dansk"
                            : "English"}{" "}
                        </span>
                      </label>

                      <div className="submenu">
                        {/*         <Link
                      className={locale === "da" ? "selected" : ""}
                      href={"/da" + thisURL}
                      stopScrollTop
                      onClick={() => toggleMenu()}
                    >
                      Dansk
                    </Link> */}
                        <Link
                          className={locale === "en" ? "selected" : ""}
                          href={"/en" + thisURL}
                          stopScrollTop
                          onClick={() => toggleMenu()}
                        >
                          English
                        </Link>
                        <Link
                          className={locale === "sv" ? "selected" : ""}
                          href={"/sv" + thisURL}
                          stopScrollTop
                          onClick={() => toggleMenu()}
                        >
                          Svenska
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
