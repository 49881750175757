const IconArrowRight = () => (
  <svg width="100%" height="100%" viewBox="0 0 9 16" version="1.1">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="iconarrowright"
        transform="translate(-293.000000, -420.000000)"
        fill="currentColor"
        stroke="currentColor"
      >
        <g id="arrow-right" transform="translate(294.000000, 421.000000)">
          <path
            d="M6.9978536,14 C6.89988623,14 6.80633844,13.9609604 6.73709834,13.8924569 L0.10772728,7.26308581 C-0.0359090932,7.11871284 -0.0359090932,6.88594825 0.10772728,6.74157528 L6.73709834,0.112204223 C6.87852492,-0.0336419401 7.1120261,-0.0380615208 7.25860886,0.103365062 C7.40445503,0.244791644 7.40887461,0.478292825 7.26744803,0.624138989 C7.26450164,0.627085376 7.26155525,0.630031763 7.25860886,0.633714747 L0.889256468,7.00233055 L7.25860886,13.3709463 C7.40224524,13.5153193 7.40224524,13.7480839 7.25787227,13.8924569 C7.18936877,13.9609604 7.09582098,14 6.9978536,14 Z"
            id="back"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
export default IconArrowRight;
