import { Link as RouterLink } from "react-router-dom";
//@ts-ignore
import lax from "lax.js";
import { HTMLAttributeAnchorTarget } from "react";

export type Props = React.PropsWithoutRef<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> & {
  stopScrollTop?: boolean;
  state?: any;
};

const getRel = (
  rel: string | undefined,
  isExternalUrl: boolean,
  target?: string
) => {
  if (isExternalUrl && target === "_blank") {
    rel = rel || "";
    if (!rel.includes("noopener")) {
      rel += " noopener";
    }
    if (!rel.includes("noreferrer")) {
      rel += " noreferrer";
    }
  }
  return rel;
};

const Link = ({
  onClick: onPropClick,
  stopScrollTop,
  href,
  target: _target,
  rel: _rel,
  children,
  ...otherProps
}: Props) => {
  const isExternalUrl =
    !!href && (href.includes("://") || href.startsWith("mailto:")); //Always new tab since it can be a browser client like outlook

  const target: HTMLAttributeAnchorTarget | undefined =
    !_target && isExternalUrl ? "_blank" : _target;

  const rel = getRel(_rel, isExternalUrl, target);

  const onClick = (e: any) => {
    if (!stopScrollTop) window.scrollTo(0, 0);
    if (!stopScrollTop) document.body.scrollTo(0, 0);

    const element = document.body.getElementsByClassName(
      "scroll-content"
    )[0] as HTMLElement;

    if (element && !stopScrollTop) element.style.transform = "";
    if (!stopScrollTop) lax.update(0);

    if (onPropClick) onPropClick(e);
  };

  if (isExternalUrl)
    return (
      <a
        rel={rel}
        target={target}
        href={href || ""}
        {...otherProps}
        onClick={onClick}
      >
        {children}
      </a>
    );

  return (
    <RouterLink
      rel={rel}
      target={target}
      to={href || ""}
      {...otherProps}
      onClick={onClick}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
