import Link from "./Link";
import { useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import IconEmail from "./icons/Email";
import IconLinkedin from "./icons/Linkedin";
import IconInstagram from "./icons/Instagram";
import { HashLink } from "react-router-hash-link";

export const Footer = () => {
  const { pathname } = useLocation();
  const { locale } = useIntl();

  const thisURL = pathname.substring(3);

  var localeInURL = locale === undefined ? "" : "/" + locale;

  return (
    <div className="slide dark" id="footer">
      <div className="slide__content">
        <div className="content bottom">
          <div className="columns">
            <div className="column">
              <Link href={"/"} className="logo"></Link>
              <p>
                <FormattedMessage
                  id="footerbody"
                  defaultMessage="Nine Agency is an innovative influencer marketing agency that operates in the Nordic region and Europe."
                />
              </p>
              <hr></hr>

              <div className="circle">
                <Link href="http://instagram.com/nine.agency">
                  <span className="icon">
                    <IconInstagram></IconInstagram>
                  </span>
                </Link>
              </div>

              <div className="circle">
                <Link href="mailto:info@nine.agency">
                  <span className="icon">
                    <IconEmail></IconEmail>
                  </span>
                </Link>
              </div>

              <div className="circle">
                <Link href="https://www.linkedin.com/company/nineagency/about/">
                  <span className="icon">
                    <IconLinkedin></IconLinkedin>
                  </span>
                </Link>
              </div>
            </div>
            <div className="column">
              <strong>
                <FormattedMessage
                  id="button_case"
                  defaultMessage="Case studies"
                />
              </strong>

              <p>
                <HashLink to={localeInURL + "/case#lunar"}>Lunar</HashLink>
              </p>

              <p>
                <HashLink to={localeInURL + "/case#skoda"}>Skoda</HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#lancome"}>Lancôme</HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#palmolive"}>
                  Palmolive
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#ajprodukter"}>
                  AJ&nbsp;Produkter
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#mandyskin"}>
                  Mandyskin
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#goteborgco"}>
                  {"Göteborg & Co"}
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#netonnet"}>
                  NetOnNet
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#panasonic"}>
                  Panasonic
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#helosan"}>Helosan</HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#rns"}>Rns</HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#casestudies"}>
                  Gainomax
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#casestudies"}>
                  Sf Studios
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/case#casestudies"}>Mtrx</HashLink>
              </p>
            </div>
            <div className="column">
              <strong>
                <FormattedMessage
                  id="button_insight"
                  defaultMessage="Insight"
                />
              </strong>
              <p>
                <Link
                  href={
                    localeInURL +
                    "/insights/get_started_with_influencer_marketing"
                  }
                >
                  <FormattedMessage
                    id="knowledge_getstarted_top_h1"
                    defaultMessage="Get started with Influencer Marketing"
                  />
                </Link>
              </p>
              <p>
                <Link
                  href={
                    localeInURL +
                    "/insights/how_to_become_a_professional_influencer"
                  }
                >
                  <FormattedMessage
                    id="knowledge2_getstarted_top_h1"
                    defaultMessage="How to Become a Professional Influencer"
                  />
                </Link>
              </p>
              <p>
                <Link
                  href={
                    localeInURL +
                    "/insights/how_to_succeed_with_influencer_marketing"
                  }
                >
                  <FormattedMessage
                    id="knowledge3_getstarted_top_h1"
                    defaultMessage="How to Succeed with Influencer Marketing"
                  />
                </Link>
              </p>
              <p>
                <Link href={localeInURL + "/webinars"}>
                  <FormattedMessage
                    id="button_knowledgesubmenu5"
                    defaultMessage="Webinars"
                    values={{ what: "react-intl" }}
                  />
                </Link>
              </p>
              <p>
                <Link href={localeInURL + "/newsletter"}>
                  <FormattedMessage
                    id="button_knowledgesubmenu4"
                    defaultMessage="Newsletter"
                    values={{ what: "react-intl" }}
                  />
                </Link>
              </p>
            </div>
            <div className="column">
              <strong>
                <FormattedMessage
                  id="button_method_short"
                  defaultMessage="Process"
                  values={{ what: "react-intl" }}
                />
              </strong>
              <p>
                <Link href={"/method"}>
                  <FormattedMessage
                    id="method_start_h1"
                    defaultMessage="Top ranked influencer agency"
                  />
                </Link>
              </p>

              <strong>
                <FormattedMessage
                  id="button_work"
                  defaultMessage="Career"
                  values={{ what: "react-intl" }}
                />
              </strong>
              <p>
                <Link href={"/career"}>
                  <FormattedMessage
                    id="work_top_h1_1"
                    defaultMessage="Join us"
                  />
                </Link>
              </p>
              <p>
                <Link href={"https://career.nine.agency/"}>
                  <FormattedMessage
                    id="button_career2"
                    defaultMessage="Career site"
                  />
                </Link>
              </p>
              <p>
                <Link href={"https://career.nine.agency/connect"}>
                  <FormattedMessage
                    id="button_connect"
                    defaultMessage="Connect"
                  />
                </Link>
              </p>
              <p>
                <Link href={"https://career.nine.agency/jobs"}>
                  <FormattedMessage
                    id="button_jobs"
                    defaultMessage="Job openings"
                  />
                </Link>
              </p>
            </div>
            <div className="column">
              <strong>
                <FormattedMessage
                  id="button_contact"
                  defaultMessage="Meet the staff"
                  values={{ what: "react-intl" }}
                />
              </strong>
              <p>
                <HashLink to={localeInURL + "/contact#marketing"}>
                  Marketing
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/contact#creative"}>
                  Creative
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/contact#executive"}>
                  <FormattedMessage
                    id="contact_contactstaff_board2"
                    defaultMessage="Executive"
                    values={{ what: "react-intl" }}
                  />
                </HashLink>
              </p>
              <p>
                <HashLink to={localeInURL + "/contact#tech"}>Tech</HashLink>
              </p>

              <strong className="header">
                <FormattedMessage
                  id="language"
                  defaultMessage="Language"
                  values={{ what: "react-intl" }}
                />
              </strong>
              {/*   <p>
                <Link
                  stopScrollTop
                  className={locale === "da" ? "selected" : ""}
                  href={"/da" + thisURL}
                >
                  {"Dansk"}
                </Link>
              </p> */}
              <p>
                <Link
                  stopScrollTop
                  className={locale === "en" ? "selected" : ""}
                  href={"/en" + thisURL}
                >
                  {"English"}
                </Link>
              </p>
              <p>
                <Link
                  stopScrollTop
                  className={locale === "sv" ? "selected" : ""}
                  href={"/sv" + thisURL}
                >
                  {"Svenska"}
                </Link>
              </p>
            </div>
          </div>

          <div className=" awards">
            <Link href={"/agencyoftheyear"}>
              <span className="logo_agency" />
            </Link>
            <span className="logo_global2024" />
            <Link
              href={"/GoldStandard"}
              title="Marketing Certification according to Gold Standard"
            >
              <span className="logo_iab" />
            </Link>

            <span className="logo_ro" />

            <span className="logo_gasell2018" />
            <Link href={"/GoldStandard"}>
              <span className="logo_iab2" />
            </Link>
            <span className="logo_gasell2019" />
          </div>

          <div className="columns address">
            <div className="colums">
              <span>
                <b>Nine & Co Group AB</b>
              </span>
              {" - "}
              <FormattedMessage
                id="address"
                defaultMessage="Gothenburg, Sweden"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
