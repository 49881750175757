const IconEmail = () => (
  <svg width="100%" height="100%" viewBox="0 0 52 52" version="1.1">
    <g
      id="Artboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="e011"
        transform="translate(3.000000, 9.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <path
          d="M24.2601097,20.3225153 C24.6774498,20.3225153 25.258095,20.0866282 25.5484176,19.7963056 L44.98191,0.362813226 L1.43346774,0.362813226 L20.848815,19.7963056 C21.1391376,20.0866282 21.7197842,20.3225153 22.1371229,20.3225153 L24.2601097,20.3225153 Z M12.6471919,16.6934787 L1.4516129,5.49789968 L1.4516129,27.8890577 L12.6471919,16.6934787 Z M45.0000552,5.49789968 L33.8044776,16.6934787 L45.0000552,27.8890577 L45.0000552,5.49789968 Z M44.98191,33.0241442 L31.2097152,19.2519494 L27.5806785,22.880986 C27.0000334,23.4797777 25.8387416,23.9515519 25.0222079,23.9515519 L21.3931713,23.9515519 C20.5584924,23.9515519 19.3972006,23.4797777 18.816554,22.880986 L15.1875174,19.2519494 L1.43346774,33.0241442 L44.98191,33.0241442 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);
export default IconEmail;
