import classNames from "classnames";
import { useState } from "react";

import "./Modal.scss";

type Props = React.PropsWithoutRef<
  React.DetailedHTMLProps<
    React.DetailsHTMLAttributes<HTMLDetailsElement>,
    HTMLDetailsElement
  >
> & {
  isOpen?: boolean;
  onClose?: VoidFunction;
  label?: React.ReactNode;
};
const Modal = ({
  isOpen: _isOpen,
  onClose: _onClose,
  className,
  label,
  children,
}: Props) => {
  const [isOpenState, setIsOpenState] = useState(false);

  const isOpen = _isOpen !== undefined ? _isOpen : isOpenState;
  const onClose =
    _onClose !== undefined ? _onClose : () => setIsOpenState((x) => !x);

  return (
    <details open={isOpen} className={classNames("modal", className)}>
      <summary>{label}</summary>
      <div
        onClick={(e) => {
          if (e.target !== e.currentTarget) return;
          onClose();
        }}
      >
        <div className="modal-content">{children}</div>
      </div>
    </details>
  );
};

export default Modal;
