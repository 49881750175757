const IconMenu = () => (
  <svg width="100%" height="100%" viewBox="0 0 22 18" version="1.1">
    <g
      id="iconMenu"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="graphic-up"
        transform="translate(11.000000, 9.000000) rotate(90.000000) translate(-11.000000, -9.000000) translate(2.000000, -2.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <path
          d="M8.5,22 C7.67158,22 7,21.32842 7,20.5 L7,11.5 C7,10.67158 7.67158,10 8.5,10 L9.5,10 C10.32842,10 11,10.67158 11,11.5 L11,20.5 C11,21.32842 10.32842,22 9.5,22 L8.5,22 Z"
          id="Path"
        ></path>
        <path
          d="M15.5,22 C14.67158,22 14,21.32842 14,20.5 L14,6.5 C14,5.67158 14.67158,5 15.5,5 L16.5,5 C17.32842,5 18,5.67158 18,6.5 L18,20.5 C18,21.32842 17.32842,22 16.5,22 L15.5,22 Z"
          id="Path"
        ></path>
        <path
          d="M1.5,22 C0.67158,22 3.10862447e-15,21.32842 3.10862447e-15,20.5 L3.10862447e-15,1.5 C3.10862447e-15,0.67158 0.67158,0 1.5,0 L2.5,0 C3.32842,0 4,0.67158 4,1.5 L4,20.5 C4,21.32842 3.32842,22 2.5,22 L1.5,22 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
);
export default IconMenu;
