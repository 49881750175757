import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { lazy, Suspense, useLayoutEffect, useState } from "react";

import { IntlProvider } from "react-intl";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Consent from "./components/consent/Consent";

import "./styles/Index.scss";
import "./styles/Menu.scss";
import "./styles/Animations.scss";
import "./styles/Icons.scss";
import "./styles/Logos.scss";
import "./styles/Tablet.scss";
import "./styles/Mobile.scss";

const Home = lazy(() => import("./pages/Home"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Contact = lazy(() => import("./pages/Contact"));
const Press = lazy(() => import("./pages/Press"));
const Work = lazy(() => import("./pages/Work"));
const Insights = lazy(() => import("./pages/Insights"));
const Knowledge1 = lazy(() => import("./pages/Knowledge"));
const Knowledge2 = lazy(() => import("./pages/Knowledge2"));
const Knowledge3 = lazy(() => import("./pages/Knowledge3"));
const Newsletter = lazy(() => import("./pages/Newsletter"));
const Case = lazy(() => import("./pages/Case"));
const Method = lazy(() => import("./pages/Method"));
const Webinars = lazy(() => import("./pages/Webinars"));
const CaseGainomax = lazy(() => import("./pages/CaseGainomax"));
const CaseSfStudios = lazy(() => import("./pages/CaseSfStudios"));
const CaseMTR = lazy(() => import("./pages/CaseMTRX"));
const GoldStandard = lazy(() => import("./pages/GoldStandard"));
const NotFound = lazy(() => import("./pages/NotFound"));

export type ImportObject = {
  [key: string]: (() => Promise<object>) | undefined;
};

const translations: ImportObject = {
  en: () => import("./translations/en.json"),
  sv: () => import("./translations/sv.json"),
  // da: () => import("./translations/da.json"),
};

const languages = Object.keys(translations);

// language from url without country code
const getUrlLanguage = (pathname: string) => {
  const currentInPath = pathname.split("/")[1];

  //Invalid format
  if (currentInPath?.match(/[a-z]{2}/) === null) return undefined;

  return currentInPath.toLowerCase();
};

// language from browser without country code
const clientDefaultLanguage = navigator.language.split(/[-_]/)[0] || "en";
const clientDefaultLanguageSupported = languages.includes(clientDefaultLanguage)
  ? clientDefaultLanguage
  : "en";

const App = () => {
  const { pathname } = useLocation();

  const urlLanguage = getUrlLanguage(pathname);

  const language =
    !urlLanguage || !languages.includes(urlLanguage) ? undefined : urlLanguage;

  const [strings, setStrings] = useState<object | undefined>(undefined);

  useLayoutEffect(() => {
    if (!language) return;

    const p = translations[language];

    if (p) p().then((m: any) => setStrings(m.default));
  }, [language]);

  //If no locale in url redirect to one
  if (!language) {
    return (
      <Navigate
        to={
          `/${clientDefaultLanguageSupported}` +
          window.location.href.replace(`${window.location.origin}`, "")
        }
        replace
      />
    );
  }

  const routeFallback = <div className="slides" />;

  if (!strings) return null;

  return (
    <IntlProvider locale={language} messages={strings as any}>
      <div className="wrapper">
        <Consent />
        <Menu />
        <Routes>
          <Route path=":locale">
            <Route
              index
              element={
                <Suspense fallback={routeFallback}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="contact"
              element={
                <Suspense fallback={routeFallback}>
                  <Contact />
                </Suspense>
              }
            />
            <Route
              path="privacy"
              element={
                <Suspense fallback={routeFallback}>
                  <Privacy />
                </Suspense>
              }
            />
            <Route
              path="career"
              element={
                <Suspense fallback={routeFallback}>
                  <Work />
                </Suspense>
              }
            />
            <Route
              path="insights"
              element={
                <Suspense fallback={routeFallback}>
                  <Insights />
                </Suspense>
              }
            />
            <Route
              path="insights/get_started_with_influencer_marketing"
              element={
                <Suspense fallback={routeFallback}>
                  <Knowledge1 />
                </Suspense>
              }
            />
            <Route
              path="insights/how_to_become_a_professional_influencer"
              element={
                <Suspense fallback={routeFallback}>
                  <Knowledge2 />
                </Suspense>
              }
            />
            <Route
              path="insights/how_to_succeed_with_influencer_marketing"
              element={
                <Suspense fallback={routeFallback}>
                  <Knowledge3 />
                </Suspense>
              }
            />
            <Route
              path="newsletter"
              element={
                <Suspense fallback={routeFallback}>
                  <Newsletter />
                </Suspense>
              }
            />
            <Route
              path="press"
              element={
                <Suspense fallback={routeFallback}>
                  <Press />
                </Suspense>
              }
            />
            <Route
              path="goldstandard"
              element={
                <Suspense fallback={routeFallback}>
                  <GoldStandard />
                </Suspense>
              }
            />

            <Route
              path="method"
              element={
                <Suspense fallback={routeFallback}>
                  <Method />
                </Suspense>
              }
            />

            <Route
              path="webinars"
              element={
                <Suspense fallback={routeFallback}>
                  <Webinars />
                </Suspense>
              }
            />

            <Route path="case">
              <Route
                index
                element={
                  <Suspense fallback={routeFallback}>
                    <Case />
                  </Suspense>
                }
              />
              <Route
                path="gainomax"
                element={
                  <Suspense fallback={routeFallback}>
                    <CaseGainomax />
                  </Suspense>
                }
              />
              <Route
                path="sf-studios"
                element={
                  <Suspense fallback={routeFallback}>
                    <CaseSfStudios />
                  </Suspense>
                }
              />
              <Route
                path="mtrx"
                element={
                  <Suspense fallback={routeFallback}>
                    <CaseMTR />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path="*"
              element={
                <Suspense fallback={routeFallback}>
                  <NotFound />
                </Suspense>
              }
            />
          </Route>
        </Routes>
        <Footer />
      </div>
    </IntlProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
